import queryString from 'query-string';
import React from 'react';

import { QuestionsRealEstate } from '../components';

const realEstateSatisfactionSurvey = (props) => {
  let param = queryString.parse(props.location.search);

  return <QuestionsRealEstate token={param.z} version={param.v} />;
};

export default realEstateSatisfactionSurvey;
